import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Bootstrap CSS
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Bootstrap JS (for modals, etc.)
import { withCookies } from 'react-cookie';
import WebsiteHome from './Website-home';
import WebsiteAbout from './Website-About';
import WebsiteProduts from './Website-Products';
import WebsiteContact from './Website-Contact';

const root = ReactDOM.createRoot(document.getElementById('root'));

function MyRouter() {
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the context menu from opening
  };

  return (
    <BrowserRouter onContextMenu={handleContextMenu}>
      <Routes>
        <Route path="/" element={<WebsiteHome />} />
        <Route path="/About" element={<WebsiteAbout />} />
        <Route path="/Contact" element={<WebsiteContact />} />
        <Route path="/AvailableProducts" element={<WebsiteProduts />} />
      </Routes>
    </BrowserRouter>
  );
}

const MyRouterWithCookies = withCookies(MyRouter);
root.render(<MyRouterWithCookies />);
