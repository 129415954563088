import React from 'react';

export default function WebsiteFooter() {
    return (
        <div
            className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn"
            data-wow-delay="0.1s"
        >
            <div className="container py-5">
                <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-white mb-3">Quick Link</h4>
                        <a className="btn btn-link text-decoration-none" href="/">
                            Home
                        </a>
                        <a className="btn btn-link text-decoration-none" href="/About">
                            About Us
                        </a>
                        <a className="btn btn-link text-decoration-none" href="/AvailableProducts">
                            Products
                        </a>
                        <a className="btn btn-link text-decoration-none" href="/Contact">
                            Contact us
                        </a>

                    </div>
                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-white mb-3">Contact</h4>
                        <p className="mb-2">
                            <i className="fa fa-map-marker-alt me-3" />
                            Kachhiyawad, Bhavnagar, Gujarat, India
                        </p>
                        <p className="mb-2">
                            <i className="fa fa-phone-alt me-3" />
                            +919898339123
                        </p>
                        <p className="mb-2">
                            <i className="fa fa-envelope me-3" />
                            info@arabiainternational.com
                        </p>
                        <div className="d-flex pt-2">
                            <a className="btn btn-outline-light btn-social" href="">
                                <i className="fab fa-twitter" />
                            </a>
                            <a className="btn btn-outline-light btn-social" href="">
                                <i className="fab fa-facebook-f" />
                            </a>
                            <a className="btn btn-outline-light btn-social" href="">
                                <i className="fab fa-youtube" />
                            </a>
                            <a className="btn btn-outline-light btn-social" href="">
                                <i className="fab fa-linkedin-in" />
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <h4 className="text-white mb-3">Newsletter</h4>
                        <div className="position-relative mx-auto" style={{ maxWidth: 400 }}>
                            <p className="text-white">
                                Arabia International exports premium electric automation products worldwide, delivering innovative solutions and exceptional service to meet diverse global client needs.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="copyright">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            ©{" "}
                            <a className="border-bottom" href="/">
                                arabiainternational.com
                            </a>
                            , All Right Reserved.
                            {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}


                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            <div className="footer-menu">
                                <a className='text-decoration-none' href="/">Home</a>
                                <a className='text-decoration-none' href="/AvailableProducts">Products</a>
                                <a className='text-decoration-none' href="/About">About</a>
                                <a className='text-decoration-none' href="/Contact">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}
