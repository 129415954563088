import React from 'react';
import { Link } from 'react-router-dom';
import WebsiteMenu from './Website-Menu';
import Carousel from 'react-bootstrap/Carousel';
import WebsiteFooter from './Website-Footer';

export default function WebsiteHome() {
    return (
        <>
            <WebsiteMenu />
            <Carousel style={{ paddingTop: "0" }}>
                <Carousel.Item>
                    <img
                        className="w-100"
                        src="img\Blank.png"
                        alt="First slide"
                        style={{ objectFit: "cover", height: "calc(100vh - 50px)" }}
                    />
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ background: "rgba(23, 29, 56, .7)" }}>
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-sm-10 col-lg-8 text-white">
                                    <h5 className="text-primary text-uppercase mb-3 fw-bold animated slideInDown" style={{ fontSize: "2rem" }}>
                                        Welcome to Arabia International
                                    </h5>
                                    <h1 className="display-3 fw-bold animated slideInDown" style={{ fontSize: "3.5rem" }}>
                                        Power up your life with Arabia International's range of ABB-certified products.
                                    </h1>
                                    <p className="fs-5 mb-4 pb-2">
                                        Connect with Arabia International on eBay for seamless international transactions.
                                    </p>
                                    <Link to="/about" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">
                                        About us
                                    </Link>
                                    <Link to="/contact" className="btn btn-light py-md-3 px-md-5 animated slideInRight">
                                        Contact us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                <img className="img-fluid position-absolute w-100 h-100" src="img\Blank.png" alt="" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start text-primary pe-4">About Us</h6>
                            <h1 className="mb-4">Welcome to Arabia International</h1>
                            <p className="mb-4">Arabia International specializes in selling premium electric products, including the ASFC-01C, ABB DM/S1.1 i-bus EIB dusk switch, ABB SACE PR120/V, and other ABB certified items. Our commitment to quality and innovation ensures reliable solutions for all your electrical needs. Trust Arabia International for excellence in electrical products.</p>
                            <p className="mb-4">Arabia International is a global exporter of premium electric products, also selling on e-commerce platforms like eBay. Our extensive range ensures quality and reliability for all your electrical needs.</p>
                            <div className="row gy-2 gx-4 mb-4">
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Leading</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Exporter</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>E-commerce</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Extensive</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Professional</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>International Certificate</p>
                                </div>
                            </div>
                            <a className="btn btn-primary py-3 px-5 mt-2" href="/Contact">Contact us</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h6 className="section-title bg-white text-center text-primary px-3 py-2">
                            Upcoming Products
                        </h6>
                        <h1 className="mb-5">New Arrival</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="img\aV-1.png" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <a
                                            href="/About"
                                            className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}
                                        >
                                            About Us
                                        </a>
                                        <a
                                            href="/Contact"
                                            className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}
                                        >
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"></h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        
                                    </div>
                                    <h5 className="mb-4">
                                        
                                    </h5>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="img/av-2.png" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <a
                                            href="/About"
                                            className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}
                                        >
                                            About Us
                                        </a>
                                        <a
                                            href="/Contact"
                                            className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}
                                        >
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"></h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        
                                    </div>
                                    <h5 className="mb-4">
                                        
                                    </h5>
                                </div>
                               
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="course-item bg-light">
                                <div className="position-relative overflow-hidden">
                                    <img className="img-fluid" src="img/av-3.png" alt="" />
                                    <div className="w-100 d-flex justify-content-center position-absolute bottom-0 start-0 mb-4">
                                        <a
                                            href="/About"
                                            className="flex-shrink-0 btn btn-sm btn-primary px-3 border-end"
                                            style={{ borderRadius: "30px 0 0 30px" }}
                                        >
                                            About Us
                                        </a>
                                        <a
                                            href="/Contact"
                                            className="flex-shrink-0 btn btn-sm btn-primary px-3"
                                            style={{ borderRadius: "0 30px 30px 0" }}
                                        >
                                            Contact Us
                                        </a>
                                    </div>
                                </div>
                                <div className="text-center p-4 pb-0">
                                    <h3 className="mb-0"></h3>
                                    <div className="mb-3">
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                        <small className="fa fa-star text-primary" />
                                    </div>
                                    <h5 className="mb-4">
                                        
                                    </h5>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebsiteFooter />

            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
                <i className="bi bi-arrow-up" />
            </a>





        </>

    );
}
