import React from 'react';
import WebsiteMenu from './Website-Menu';
import WebsiteFooter from './Website-Footer';

export default function WebsiteAbout() {
    return (
        <>
            <WebsiteMenu />
            <div className="container-fluid bg-primary py-5 mb-5 page-header">
                <div className="container py-5">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 text-center">
                            <h1 className="display-3 text-white animated slideInDown">About Us</h1>
                            <nav aria-label="">
                                <ol className="breadcrumb justify-content-center">
                                    <li className="">
                                        <a className="text-white text-decoration-none" href="#">
                                            Home/
                                        </a>
                                    </li>
                                    <li className="">
                                        <a className="text-white text-decoration-none " href="#">
                                            Pages/
                                        </a>
                                    </li>
                                    <li
                                        className=" text-white active"
                                        aria-current="page"
                                    >
                                        About
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5">
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s" style={{ minHeight: "400px" }}>
                            <div className="position-relative h-100">
                                <img className="img-fluid position-absolute w-100 h-100" src="img\Blank.png" alt="" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
                            <h6 className="section-title bg-white text-start text-primary pe-4">About Us</h6>
                            <h1 className="mb-4">Welcome to Arabia International</h1>
                            <p className="mb-4">Arabia International specializes in selling premium electric products, including the ASFC-01C, ABB DM/S1.1 i-bus EIB dusk switch, ABB SACE PR120/V, and other ABB certified items. Our commitment to quality and innovation ensures reliable solutions for all your electrical needs. Trust Arabia International for excellence in electrical products.</p>
                            <p className="mb-4">Arabia International is a global exporter of premium electric products, also selling on e-commerce platforms like eBay. Our extensive range ensures quality and reliability for all your electrical needs.</p>
                            <div className="row gy-2 gx-4 mb-4">
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Leading</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Exporter</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>E-commerce</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Extensive</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>Professional</p>
                                </div>
                                <div className="col-sm-6">
                                    <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>International Certificate</p>
                                </div>
                            </div>
                            <a className="btn btn-primary py-3 px-5 mt-2" href="">Contact us</a>
                        </div>
                    </div>
                </div>
            </div>


            <WebsiteFooter />

            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
                <i className="bi bi-arrow-up" />
            </a>





        </>

    );
}
