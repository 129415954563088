import React from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

export default function WebsiteMenu() {
    return (
        <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
            <Link to="/" className="navbar-brand d-flex align-items-center px-4 px-lg-5">
                <h2 className="m-0 text-primary">
                    <img className="logo" src="img/logo.png" alt="Logo" />
                </h2>
            </Link>
            <button
                className="navbar-toggler me-4"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarCollapse"
                aria-controls="navbarCollapse"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <div className="navbar-nav ms-auto p-4 p-lg-0">
                    <Link to="/" className="nav-item nav-link active">
                        Home
                    </Link>
                    <Link to="/About" className="nav-item nav-link">
                        About
                    </Link>
                    <Link to="/AvailableProducts" className="nav-item nav-link">
                        Products
                    </Link>
                    <Link to="/Contact" className="nav-item nav-link">
                        Contact
                    </Link>
                </div>
            </div>
        </nav>
    );
}
